import React from 'react';
import { Component, useState  } from 'react';


// Start Openlayers imports
import { Map, View} from 'ol'
import { GeoJSON } from 'ol/format'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource, OSM as OSMSource, TileWMS as TileWMSSource } from 'ol/source'
import { Select as SelectInteraction, defaults as DefaultInteractions } from 'ol/interaction'
import { Attribution, ScaleLine, ZoomSlider, Zoom, Rotate, MousePosition, OverviewMap, defaults as DefaultControls} from 'ol/control'
import {Style,Fill as FillStyle, RegularShape as RegularShapeStyle, Stroke as StrokeStyle} from 'ol/style'
import { Projection,get as getProjection} from 'ol/proj'

import Overlay from 'ol/Overlay.js';
import XYZ from 'ol/source/XYZ.js';
import {toLonLat} from 'ol/proj.js';
import {toStringHDMS} from 'ol/coordinate.js';

// interface myLayerObject {

//     // source: any;
//     // visible: boolean;

// }


// const layerArray:  myLayerObject[] = [];

const URLArray = [];


// DEVELOPMENT
// const APP_DOMAIN = "http://localhost:3000";
// const GEOSERVER_BASE = "http://localhost:8080/geoserver";
// const OSM = "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png";
// const ICON_IMG = "http://localhost/img";

// const SEMPADAN_PAHANG = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Apahang_boundary&outputFormat=application%2Fjson";
// const JALAN_PERSEKUTUAN = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Afederal_road&outputFormat=application%2Fjson";
// const JALAN_NEGERI_PAHANG = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Astate_road_line&outputFormat=application%2Fjson"
// const SEMPADAN_MUKIM = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Amukim_boundary&outputFormat=application%2Fjson";
// const KAMPUNG = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Akampung&outputFormat=application%2Fjson"; 

// const JKR_KOORDINAT_MULA = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&CQL_FILTER=MULA_AKHIR='Start'&outputFormat=application%2Fjson";
// const JKR_KOORDINAT_AKHIR = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&CQL_FILTER=MULA_AKHIR='End'&outputFormat=application%2Fjson";

// const JPS_KOORDINAT_MULA = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_koordinat&CQL_FILTER=MULA_AKHIR='Start'&outputFormat=application%2Fjson";
// const JPS_KOORDINAT_AKHIR = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_koordinat&CQL_FILTER=MULA_AKHIR='End'&outputFormat=application%2Fjson";

// const JKR_JALAN = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_roadline&outputFormat=application%2Fjson";

// const JPS_JALAN = "http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_roadline&outputFormat=application%2Fjson";

// &CQL_FILTER=MULA_AKHIR='Start'

// STAGING
// const APP_DOMAIN = "http://localhost:3000";
// const GEOSERVER_BASE = "http://localhost:8181/geoserver"; 
// const OSM = "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png";
// const ICON_IMG = "https://pahangroad.szyconsultants.com/img";

// const SEMPADAN_PAHANG = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Apahang_boundary&outputFormat=application%2Fjson";
// const JALAN_PERSEKUTUAN = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Afederal_road&outputFormat=application%2Fjson";
// const JALAN_NEGERI_PAHANG = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Astate_road_line&outputFormat=application%2Fjson"
// const SEMPADAN_MUKIM = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Amukim_boundary&outputFormat=application%2Fjson";
// const KAMPUNG = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Akampung&outputFormat=application%2Fjson"; 

// const JKR_KOORDINAT_MULA = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&CQL_FILTER=MULA_AKHIR='Start'&outputFormat=application%2Fjson";
// const JKR_KOORDINAT_AKHIR = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&CQL_FILTER=MULA_AKHIR='End'&outputFormat=application%2Fjson";

// const JPS_KOORDINAT_MULA = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_koordinat&CQL_FILTER=MULA_AKHIR='Start'&outputFormat=application%2Fjson";
// const JPS_KOORDINAT_AKHIR = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_koordinat&CQL_FILTER=MULA_AKHIR='End'&outputFormat=application%2Fjson";

// const JKR_JALAN = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_roadline&outputFormat=application%2Fjson";
// const JPS_JALAN = "http://localhost:8181/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_roadline&outputFormat=application%2Fjson";


// PRODUCTION - WINDOWS SERVER
// const APP_DOMAIN = "https://pahangroad.szyconsultants.com/";
// const APP_DOMAIN = "https://szywebmapservice.com/";
// const GEOSERVER_BASE = "https://szywebmapservice.com/geoserver"; 
// const OSM = "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png";
// const ICON_IMG = "https://pahangroad.szyconsultants.com/img";

// const SEMPADAN_PAHANG = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Apahang_boundary&outputFormat=application%2Fjson";
// const JALAN_PERSEKUTUAN = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Afederal_road&outputFormat=application%2Fjson";
// const JALAN_NEGERI_PAHANG = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Astate_road_line&outputFormat=application%2Fjson"
// const SEMPADAN_MUKIM = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Amukim_boundary&outputFormat=application%2Fjson";
// const KAMPUNG = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Akampung&outputFormat=application%2Fjson"; 

// const JKR_KOORDINAT_MULA = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&CQL_FILTER=MULA_AKHIR='Start'&outputFormat=application%2Fjson";
// const JKR_KOORDINAT_AKHIR = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_koordinat&CQL_FILTER=MULA_AKHIR='End'&outputFormat=application%2Fjson";

// const JPS_KOORDINAT_MULA = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_koordinat&CQL_FILTER=MULA_AKHIR='Start'&outputFormat=application%2Fjson";
// const JPS_KOORDINAT_AKHIR = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_koordinat&CQL_FILTER=MULA_AKHIR='End'&outputFormat=application%2Fjson";

// const JKR_JALAN = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKR_roadline&outputFormat=application%2Fjson";
// const JPS_JALAN = "https://szywebmapservice.com/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJPS_roadline&outputFormat=application%2Fjson";

// PRODUCTION CPANEL
// Notes:  This data will retrieve from json files uploaded in cpanel because geoserver cannot be installed in Cpanel due to accessibility issue. 
const APP_DOMAIN = "https://pahangroad.szyconsultants.com";
const GEOSERVER_BASE = "http://localhost:8080/geoserver"; // Unused service
const OSM = "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const ICON_IMG = "https://pahangroad.szyconsultants.com/images";
const GIS_GEOJSON_BASEPATH = "https://pahangroad.szyconsultants.com/geojson";

const SEMPADAN_PAHANG = GIS_GEOJSON_BASEPATH+"/SEMPADAN_PAHANG.json";
const JALAN_PERSEKUTUAN = GIS_GEOJSON_BASEPATH+"/JALAN_PERSEKUTUAN.json";
const JALAN_NEGERI_PAHANG = GIS_GEOJSON_BASEPATH+"/JALAN_NEGERI_PAHANG.json"
const SEMPADAN_MUKIM = GIS_GEOJSON_BASEPATH+"/SEMPADAN_MUKIM.json";
const KAMPUNG = GIS_GEOJSON_BASEPATH+"/KAMPUNG.json"; 

const JKR_KOORDINAT_MULA = GIS_GEOJSON_BASEPATH+"/JKR_KOORDINAT_MULA.json";
const JKR_KOORDINAT_AKHIR = GIS_GEOJSON_BASEPATH+"/JKR_KOORDINAT_AKHIR.json";

const JPS_KOORDINAT_MULA = GIS_GEOJSON_BASEPATH+"/JPS_KOORDINAT_MULA.json";
const JPS_KOORDINAT_AKHIR = GIS_GEOJSON_BASEPATH+"/JPS_KOORDINAT_AKHIR.json";

const JKR_JALAN = GIS_GEOJSON_BASEPATH+"/JKR_JALAN.json";
const JPS_JALAN = GIS_GEOJSON_BASEPATH+"/JPS_JALAN.json";

const JKR_AND_JPS_50_DATA = GIS_GEOJSON_BASEPATH+"/JKR_AND_JPS_50_DATA.json";
const JKR_AND_JPS_ALL_DATA = GIS_GEOJSON_BASEPATH+"/JKR_AND_JPS_ALL_DATA.json";
const JKR_AND_JPS_CENTROID = GIS_GEOJSON_BASEPATH+"/JKR_AND_JPS_CENTROID.json";

// ===========================================================
// URL ARRANGEMENT
// ===========================================================
// APP_DOMAIN - configURL[0]
// GEOSERVER_BASE - configURL[1] 
// OSM - configURL[2] 
// JALAN_PERSEKUTUAN - configURL[3] 
// JALAN_NEGERI_PAHANG - configURL[4] 
// SEMPADAN_MUKIM - configURL[5] 
// KAMPUNG - configURL[6]
// JKR_KOORDINAT_MULA - configURL[7] 
// JKR_JALAN - configURL[8] 
// JPS_JALAN - configURL[9] 
// ICON_IMG - configURL[10] 
// JPS_KOORDINAT_MULA - configURL[11] 
// JKR_KOORDINAT_AKHIR - configURL[12] 
// JPS_KOORDINAT_AKHIR - configURL[13]
// JKR_AND_JPS_50_DATA - configURL[14]
// JKR_AND_JPS_ALL_DATA - configURL[15]
// JKR_AND_JPS_CENTROID - configURL[16]
// ==========================================================


URLArray.push(APP_DOMAIN, GEOSERVER_BASE, OSM, JALAN_PERSEKUTUAN, JALAN_NEGERI_PAHANG, SEMPADAN_MUKIM, KAMPUNG, JKR_KOORDINAT_MULA, JKR_JALAN, JPS_JALAN, ICON_IMG, JPS_KOORDINAT_MULA, JKR_KOORDINAT_AKHIR, JPS_KOORDINAT_AKHIR, JKR_AND_JPS_50_DATA, JKR_AND_JPS_ALL_DATA, JKR_AND_JPS_CENTROID);
export const configURL: any[] = URLArray;


  export function geConfigURL(): any[] {
 
    return configURL;
    

}