import React from 'react';
import { Component, useState, useEffect, useRef } from 'react';
import 'ol/ol.css';
import './MapComponent.css';
import '../Layer/LegendLayer.css';

// Start Openlayers imports
import { Map, View, Feature} from 'ol'
import { GeoJSON } from 'ol/format'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource, OSM as OSMSource, TileWMS as TileWMSSource } from 'ol/source'
import { Select as SelectInteraction, defaults as DefaultInteractions } from 'ol/interaction'
import { Attribution, ScaleLine, ZoomSlider, Zoom, Rotate, MousePosition, OverviewMap, defaults as DefaultControls} from 'ol/control'
import {Style,Text, Stroke, Fill as FillStyle, RegularShape as RegularShapeStyle, Stroke as StrokeStyle} from 'ol/style'
import { Projection,get as getProjection} from 'ol/proj'

import Overlay from 'ol/Overlay.js';
import XYZ from 'ol/source/XYZ.js';
import {toLonLat} from 'ol/proj.js';
import {toStringHDMS} from 'ol/coordinate.js';
// import LegendLayer from '../Layer/LegendLayer';
import { FALSE } from 'ol/functions';
// import VectorTileLayer from 'ol/layer/VectorTile.js';
// import VectorTileSource from 'ol/source/VectorTile.js';
// import LayerSwitcher from 'ol-layerswitcher';
// import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';

import Select from 'ol/interaction/Select';
import { click } from 'ol/events/condition';
import { singleClick } from 'ol/events/condition';
import { click as clickInteraction } from 'ol/events/condition';

import {Layers} from '../Layer/Layer'
import {configURL} from '../config/config'

import {Fragment} from 'react'
import {Layers as LayerIcon} from '@styled-icons/material'
import {Binoculars} from '@styled-icons/boxicons-solid/Binoculars'
import {SearchOutline} from '@styled-icons/evaicons-outline/SearchOutline';
import {ManageSearch} from '@styled-icons/material-outlined/ManageSearch'
import {Book} from '@styled-icons/icomoon/Book';
import {Search} from '@styled-icons/boxicons-solid/Search';
import {RoadMap} from '@styled-icons/remix-line/RoadMap'
import {CloseCircle} from '@styled-icons/evaicons-solid/CloseCircle';
import { useLocation } from 'react-router-dom';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import AdvanceSearch from '../Search/AdvanceSearch';
import Form from 'react-bootstrap/Form';
import Pdf from '../Documents/UserManual1.2.pdf';
import Point from 'ol/geom/Point.js';
import {Icon} from 'ol/style.js';
import { Button, Container, Col, Row, Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import { BackgroundColor } from 'styled-icons/foundation';


interface Item {
    id: number;
    name: string;
  }


const MapComponent: React.FC = () => {


    const [isJlnPersekutuanChecked, setJlnPersekutuanChecked] = useState(false);

    const [isJlnPahangChecked, setJlnPahangChecked] = useState(false);
    
    const [isSempMukimChecked, setSempMukimChecked] = useState(false);

    const [isKampungChecked, setKampungChecked] = useState(false);
    
    // const [isKoordinatMulaJKRChecked, setKoordinatMulaJKRChecked] = useState(false);

    // const [isKoordinatMulaJPSChecked, setKoordinatMulaJPSChecked] = useState(false);
    
    const [isKoordinatMulaJKRandJPSChecked, setKoordinatMulaJKRandJPSChecked] = useState(false);

    const [isKoordinatAkhirJKRandJPSChecked, setKoordinatAkhirJKRandJPSChecked] = useState(false);

    // const [isKoordinatAkhirJKRChecked, setKoordinatAkhirJKRChecked] = useState(false);

    // const [isKoordinatAkhirJPSChecked, setKoordinatAkhirJPSChecked] = useState(false);
    
    const [isJlnJKRChecked, setJlnJKRChecked] = useState(false);
    
    const [isJlnJPSChecked, setJlnJPSChecked] = useState(false);

    const [isOSMChecked, setOSMChecked] = useState(true);

    const [isImageryChecked, setImageryChecked] = useState(false);

    // define containerRef
    const containerRef = useRef<HTMLDivElement>(null);
    
    

  function closeAttributeInfo() {

    const attributeInfoDiv = document.getElementById('attributeInfo') as HTMLElement
    attributeInfoDiv.style.display = 'none';

    // const attributeInfoCloseButton = document.getElementById('attributeInfoCloseButton') as HTMLElement
    // attributeInfoCloseButton.style.display = 'none';

   }

    useEffect(() => {

      

   
        const mapInit = new Map({
            //  Display the map in the div with the id of map
              // ============== Layer Arrangement - Reference ========================
              /*
              osm_basemap - Layers[0], 
              world_imagery - Layers[8]
              sempadan_mukim_vector - Layers[3]
              jalan_persekutuan_vector - Layers[1], 
              jalan_pahang_vector - Layers[2] 
              jkr_jalan_vector - Layers[6]  
              jps_jalan_vector - Layers[7] 
              kampung_vector - Layers[4] 
              jkr_koordinat_mula_vector - Layers[5] 
              jPS_koordinat_mula_vector - Layers[9]
              jkr_koordinat_akhir_vector - Layers[10]
              jPS_koordinat_akhir_vector - Layers[11]
              */
             // =======================================================================
            target: 'mapDiv',
            layers: [Layers[0], Layers[3], Layers[1], Layers[2], Layers[6], Layers[7], Layers[4], Layers[5], Layers[9], Layers[10], Layers[11]],
            // overlays: [overlay],
            // Add in the following map controls
            controls: DefaultControls().extend([
                // new ZoomSlider(),
                new MousePosition(),
                new ScaleLine({
                  units: 'metric',
                  bar: true,
                  steps: 1,
                  text: true,
                  minWidth: 140,
                }),
                // new OverviewMap()
            ]),
            // Render the tile layers in a map view with a Mercator projection
            view: new View({
                projection: 'EPSG:4326',
                // center: [102.509771,],
                center: [102.9475037,3.901093],
                zoom: 8,
                // minZoom:6,
              //   extent: [west, south, east, north]   
                // extent:[101.33158999987864,2.459989999938614,104.22334000034078,4.781479984779905]
                extent: [98.328736,1.817945,106.672852,5.626932]
            })
        })
  
        const params = new URLSearchParams(window.location.search);
        console.log(params.get('x'));

        if (params.get('x') != null) {

          var coord = [parseFloat(params.get('x')), parseFloat(params.get('y'))];

          mapInit.setView(
            new View({
            projection: 'EPSG:4326',  
            center: coord,
            zoom: 17,
            extent:[101.33158999987864,2.459989999938614,104.22334000034078,4.781479984779905]
        }));

        const iconFeature = new Feature({
          geometry: new Point([parseFloat(params.get('x')), parseFloat(params.get('y'))]),
        });
        
        const iconStyle = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            scale: 0.8,
            offset: [0,0],
            src: configURL[10]+'/round_marker.png',
          }),
          text: new Text({
            text: params.get('nj'),
            font: 'bold 12px Calibri,sans-serif',
            fill: new FillStyle({
              color: 'black',
            }),
            stroke: new Stroke({
              color: 'white',
              width: 1,
            }),
          }),
        });
        
        iconFeature.setStyle(iconStyle);

        const PtJalanvectorSource = new VectorSource({
          features: [iconFeature],
        });
        
        const PtJalanvectorLayer = new VectorLayer({
          source: PtJalanvectorSource,
        });

        mapInit.getLayers().extend([PtJalanvectorLayer]);

        }
  
  
        const select = new Select({
          condition: clickInteraction,
          layers: [Layers[3], Layers[1], Layers[2], Layers[6], Layers[7], Layers[4], Layers[5], Layers[9], Layers[10], Layers[11]],
        });



        const attributeInfoDiv = document.getElementById('attributeInfo') as HTMLElement

        attributeInfoDiv.style.display = 'none';

        // const attributeInfoCloseButton = document.getElementById('attributeInfoCloseButton') as HTMLElement

        // const headerAttInfo = document.getElementById('headerAttInfo') as HTMLElement
        // headerAttInfo.style.display = 'none';  

        function closeAttributeInfo() {

          const attributeInfoDiv = document.getElementById('attributeInfo') as HTMLElement
          attributeInfoDiv.style.display = 'none';
      
          // const attributeInfoCloseButton = document.getElementById('attributeInfoCloseButton') as HTMLElement
          // attributeInfoCloseButton.style.display = 'none';
      
         }

      
        select.on('select', (event) => {
          if (event.selected.length > 0) {
            
            // console.log(event);
            console.log(event.selected);
            // console.log(event.selected[0]["id_"]);

          // Define attributes based on layer types
          let layer_types = event.selected[0]["id_"].split(".");
          let dataLayer;

          console.log(layer_types[0]);

          // Access attributes of the selected features
          const selectedFeature = event.selected[0];
          const attributes = selectedFeature.getProperties();
          console.log('Selected feature attributes:', attributes);
          
          // delete geometry to make data less heavy
          delete attributes.geometry;


      

          // dynamically assign attributes info based on layer
          switch (layer_types[0]) {
            case "federal_road":
              dataLayer = `<table>
                            <tr><td colspan="2" id="tdHeader"><button id="headerAttInfo">x</button></td></tr>
                            <tr><th colspan="2">Jalan Persekutuan</th></tr>
                            <tr id="trData"><td>ROAD NAME</td><td>`+selectedFeature.getProperties().RD_NAME+`</td></tr>
                            <tr id="trData"><td>ROUTE NO</td><td>`+selectedFeature.getProperties().ROUTE_NO+`</td></tr>
                            <tr id="trData"><td></td><td>`+selectedFeature.getProperties().DISTRICT+`</td></tr>
                            <tr id="trData"><td></td><td>`+selectedFeature.getProperties().STATE+`</td></tr>
                            </table> `;

              break;
            case "state_road_line":
              dataLayer = `<table>
                            <tr><td colspan="2" id="tdHeader"><button id="headerAttInfo">x</button></td></tr>
                            <tr><th colspan="2">Jalan Negeri Pahang</th></tr>
                            <tr id="trData"><td>JKR CODE</td><td>`+selectedFeature.getProperties().JKR_CODE+`</td></tr>
                            <tr id="trData"><td>MARRIS NO</td><td>`+selectedFeature.getProperties().MARRIS_NO+`</td></tr>
                            <tr id="trData"><td></td><td>`+selectedFeature.getProperties().NAME+`</td></tr>
                            <tr id="trData"><td></td><td>`+selectedFeature.getProperties().DISTRICT+`</td></tr>
                            <tr id="trData"><td>LENGTH</td><td>`+selectedFeature.getProperties().GPS_LENGTH+`</td></tr>
                            </table> `;
            break;
            case "mukim_boundary":
              dataLayer = `<table>
                            <tr><td colspan="2" id="tdHeader"><button id="headerAttInfo">x</button></td></tr>
                            <tr><th colspan="2">Sempadan Mukim</th></tr>
                            <tr id="trData"><td>MUKIM</td><td>`+selectedFeature.getProperties().POLYGON_NM+`</td></tr>
                            <tr id="trData"><td>NAMA MUKIM</td><td>`+selectedFeature.getProperties().FEAT_TYPE+`</td></tr>
                            </table> `;
            break;
            case "kampung":
              dataLayer = `<table>
                          <tr><td colspan="2" id="tdHeader"><button id="headerAttInfo">x</button></td></tr>
                          <tr><th colspan="2">Kampung</th></tr>
                          <tr id="trData"><td>NAMA KAMPUNG</td><td>`+selectedFeature.getProperties().Official_n+`</td></tr>
                          <tr id="trData"><td>DAERAH</td><td>`+selectedFeature.getProperties().Daerah+`</td></tr>
                          <tr id="trData"><td>MUKIM</td><td>`+selectedFeature.getProperties().Mukim+`</td></tr>
                          </table> `;
            break;
            case "JKR_koordinat":
              dataLayer = `<table>
                            <tr><td colspan="2" id="tdHeader"><button id="headerAttInfo">x</button></td></tr>
                            <tr><th colspan="2">Koordinat Mula/Akhir Jalan JKR</th></tr>
                            <tr id="trData"><td>KOORDINAT</td><td>`+selectedFeature.getProperties().MULA_AKHIR+`</td></tr>
                            <tr id="trData"><td>DAERAH</td><td>`+selectedFeature.getProperties().DAERAH+`</td></tr>
                            <tr id="trData"><td>KATEGORI</td><td>`+selectedFeature.getProperties().KATEGORI+`</td></tr>
                            <tr id="trData"><td>KOD JALAN</td><td>`+selectedFeature.getProperties().KOD_JALAN+`</td></tr>
                            <tr id="trData"><td>NAMA KAMPUNG</td><td>`+selectedFeature.getProperties().NAMA_KAMPU+`</td></tr>
                            <tr id="trData"><td>NAMA JALAN</td><td>`+selectedFeature.getProperties().NAMA_JALAN+`</td></tr>
                            <tr id="trData"><td>PANJANG JALAN</td><td>`+selectedFeature.getProperties().PANJANG_JA+`</td></tr>
                            <tr id="trData"><td>PURATA LEBAR JALAN</td><td>`+selectedFeature.getProperties().LEBAR_JALA+`</td></tr>
                            <tr id="trData"><td>SRT (KOD)</td><td>`+selectedFeature.getProperties().SRT_KOD+`</td></tr>
                            <tr id="trData"><td>TARIKH KEMASKINI</td><td>`+selectedFeature.getProperties().TARIKH_KEM+`</td></tr>
                            <tr id="trData"><td>PIHAK KUASA</td><td>`+selectedFeature.getProperties().PIHAK_KUAS+`</td></tr>
                            <tr id="trData"><td>LATITUDE</td><td>`+selectedFeature.getProperties().LATITUDE+`</td></tr>
                            <tr id="trData"><td>LONGITUDE</td><td>`+selectedFeature.getProperties().LONGITUDE+`</td></tr>                            
                            </table> `;
            break;
            case "JKR_roadline":
              dataLayer = `<table>
                          <tr><td colspan="2" id="tdHeader"><button id="headerAttInfo">x</button></td></tr>
                          <tr><th colspan="2">Jalan Kampung JKR</th></tr>
                          <tr id="trData"><td>DAERAH</td><td>`+selectedFeature.getProperties().DAERAH+`</td></tr>
                          <tr id="trData"><td>KATEGORI</td><td>`+selectedFeature.getProperties().KATEGORI+`</td></tr>
                          <tr id="trData"><td>DUN</td><td>`+selectedFeature.getProperties().DUN+`</td></tr>
                          <tr id="trData"><td>MUKIM</td><td>`+selectedFeature.getProperties().MUKIM+`</td></tr>
                          <tr id="trData"><td>KOD JALAN</td><td>`+selectedFeature.getProperties().KOD_JALAN+`</td></tr>
                          <tr id="trData"><td>ID JALAN</td><td>`+selectedFeature.getProperties().ID_JALAN+`</td></tr>
                          <tr id="trData"><td>NAMA KAMPUNG</td><td>`+selectedFeature.getProperties().NAMA_KAMPU+`</td></tr>
                          <tr id="trData"><td>NAMA LADANG</td><td>`+selectedFeature.getProperties().NAMA_LADAN+`</td></tr>
                          <tr id="trData"><td>NAMA JALAN</td><td>`+selectedFeature.getProperties().NAMA_JALAN+`</td></tr>
                          <tr id="trData"><td>PANJANG JALAN</td><td>`+selectedFeature.getProperties().PANJANG_JA+`</td></tr>
                          <tr id="trData"><td>PURATA LEBAR JALAN</td><td>`+selectedFeature.getProperties().LEBAR_JALA+`</td></tr>
                          <tr id="trData"><td>SRT (KOD)</td><td>`+selectedFeature.getProperties().SRT_KOD+`</td></tr>
                          <tr id="trData"><td>SRT</td><td>`+selectedFeature.getProperties().SRT+`</td></tr>
                          <tr id="trData"><td>TAHUN</td><td>`+selectedFeature.getProperties().TAHUN+`</td></tr>
                          <tr id="trData"><td>TARIKH KEMASKINI</td><td>`+selectedFeature.getProperties().TARIKH_KEM+`</td></tr>
                          <tr id="trData"><td>PIHAK KUASA</td><td>`+selectedFeature.getProperties().PIHAK_KUAS+`</td></tr>
                          <tr id="trData"><td>KATEGORI JALAN</td><td>`+selectedFeature.getProperties().KATEGORI_J+`</td></tr>
                          <tr id="trData"><td>PENGURUSAN</td><td>`+selectedFeature.getProperties().AUTHORITY+`</td></tr>
                          <tr id="trData"><td>PENGUKURAN</td><td>`+selectedFeature.getProperties().SURVYD_BY+`</td></tr>
                          </table> `;
            break;
            case "JPS_koordinat":
              dataLayer = `<table>
                            <tr><td colspan="2" id="tdHeader"><button id="headerAttInfo">x</button></td></tr>
                            <tr><th colspan="2">Koordinat Mula/Akhir Jalan JPS</th></tr>
                            <tr id="trData"><td>KOORDINAT</td><td>`+selectedFeature.getProperties().MULA_AKHIR+`</td></tr>
                            <tr id="trData"><td>DAERAH</td><td>`+selectedFeature.getProperties().DAERAH+`</td></tr>
                            <tr id="trData"><td>KATEGORI</td><td>`+selectedFeature.getProperties().KATEGORI+`</td></tr>
                            <tr id="trData"><td>KOD JALAN</td><td>`+selectedFeature.getProperties().KOD_JALAN+`</td></tr>
                            <tr id="trData"><td>NAMA KAMPUNG</td><td>`+selectedFeature.getProperties().NAMA_KAMPU+`</td></tr>
                            <tr id="trData"><td>NAMA JALAN</td><td>`+selectedFeature.getProperties().NAMA_JALAN+`</td></tr>
                            <tr id="trData"><td>PANJANG JALAN</td><td>`+selectedFeature.getProperties().PANJANG_JA+`</td></tr>
                            <tr id="trData"><td>PURATA LEBAR JALAN</td><td>`+selectedFeature.getProperties().LEBAR_JALA+`</td></tr>
                            <tr id="trData"><td>SRT (KOD)</td><td>`+selectedFeature.getProperties().SRT_KOD+`</td></tr>
                            <tr id="trData"><td>TARIKH KEMASKINI</td><td>`+selectedFeature.getProperties().TARIKH_KEM+`</td></tr>
                            <tr id="trData"><td>PIHAK KUASA</td><td>`+selectedFeature.getProperties().PIHAK_KUAS+`</td></tr>
                            <tr id="trData"><td>LATITUDE</td><td>`+selectedFeature.getProperties().LATITUDE+`</td></tr>
                            <tr><td>LONGITUDE</td><td>`+selectedFeature.getProperties().LONGITUDE+`</td></tr>
                            </table> `;
            break;
            case "JPS_roadline":
            dataLayer = `<table>
            <tr><td colspan="2" id="tdHeader"><button id="headerAttInfo">x</button></td></tr>
            <tr><th colspan="2">Jalan Pertanian JPS</th></tr>
            <tr id="trData"><td>DAERAH</td><td>`+selectedFeature.getProperties().DAERAH+`</td></tr>
            <tr id="trData"><td>KATEGORI</td><td>`+selectedFeature.getProperties().KATEGORI+`</td></tr>
            <tr id="trData"><td>DUN</td><td>`+selectedFeature.getProperties().DUN+`</td></tr>
            <tr id="trData"><td>MUKIM</td><td>`+selectedFeature.getProperties().MUKIM+`</td></tr>
            <tr id="trData"><td>KOD JALAN</td><td>`+selectedFeature.getProperties().KOD_JALAN+`</td></tr>
            <tr id="trData"><td>ID JALAN</td><td>`+selectedFeature.getProperties().ID_JALAN+`</td></tr>
            <tr id="trData"><td>NAMA KAMPUNG</td><td>`+selectedFeature.getProperties().NAMA_KAMPU+`</td></tr>
            <tr id="trData"><td>NAMA LADANG</td><td>`+selectedFeature.getProperties().NAMA_LADAN+`</td></tr>
            <tr id="trData"><td>NAMA JALAN</td><td>`+selectedFeature.getProperties().NAMA_JALAN+`</td></tr>
            <tr id="trData"><td>PANJANG JALAN</td><td>`+selectedFeature.getProperties().PANJANG_JA+`</td></tr>
            <tr id="trData"><td>PURATA LEBAR JALAN</td><td>`+selectedFeature.getProperties().LEBAR_JALA+`</td></tr>
            <tr id="trData"><td>SRT (KOD)</td><td>`+selectedFeature.getProperties().SRT_KOD+`</td></tr>
            <tr id="trData"><td>SRT</td><td>`+selectedFeature.getProperties().SRT+`</td></tr>
            <tr id="trData"><td>TAHUN</td><td>`+selectedFeature.getProperties().TAHUN+`</td></tr>
            <tr id="trData"><td>TARIKH KEMASKINI</td><td>`+selectedFeature.getProperties().TARIKH_KEM+`</td></tr>
            <tr id="trData"><td>PIHAK KUASA</td><td>`+selectedFeature.getProperties().PIHAK_KUAS+`</td></tr>
            <tr id="trData"><td>KATEGORI JALAN</td><td>`+selectedFeature.getProperties().KATEGORI_J+`</td></tr>
            <tr id="trData"><td>PENGURUSAN</td><td>`+selectedFeature.getProperties().AUTHORITY+`</td></tr>
            <tr id="trData"><td>PENGUKURAN</td><td>`+selectedFeature.getProperties().SURVYD_BY+`</td></tr>
                        </table> `;
            break;
          }

            attributeInfoDiv.style.display = 'block';
            // headerAttInfo.style.display = 'block';



            const attributeInfo = dataLayer;
            // document.getElementById('headerAttInfo').innerHTML = `<table>
            //                                                       <tr><td colspan="2">Maklumat attribut</td></tr>
            //                                                       </table>`;

            document.getElementById('attributeInfo').innerHTML = attributeInfo;
            document.getElementById('headerAttInfo').addEventListener("click",closeAttributeInfoTest);

      
        



           
        
              



          }
        });
      
        mapInit.addInteraction(select);  

        // console.log(mapInit.addInteraction(select));

        // const closeInfo = document.getElementById('headerAttInfo') as HTMLElement;

        


        const closeAttributeInfoTest = () => {

          const attributeInfoDiv = document.getElementById('attributeInfo') as HTMLElement
          attributeInfoDiv.style.display = 'none';
      
          // const attributeInfoCloseButton = document.getElementById('attributeInfoCloseButton') as HTMLElement
          // attributeInfoCloseButton.style.display = 'none';
      
         }

        //  document.getElementById('headerAttInfo').addEventListener("click",closeAttributeInfoTest);




       
    const handleCheckboxJlnPersekutuan = (event: Event) => {
      const checkbox = event.target as HTMLInputElement;
      // Add your condition here
      if (checkbox.checked) {
        console.log('Checkbox is checked');
        Layers[1].setVisible(true);
        // Do something when the checkbox is checked
      } else {
        console.log('Checkbox is unchecked');
        Layers[1].setVisible(false);
        // Do something when the checkbox is unchecked
      }

  }

  // Attach the event listener to the checkbox
const JlnPersekutuanCB = document.getElementById('JlnPersekutuanCB') as HTMLInputElement;
JlnPersekutuanCB.addEventListener('change', handleCheckboxJlnPersekutuan);


const handleCheckboxJlnPahang = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[2].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[2].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const JlnPahangCB = document.getElementById('JlnPahang_CB') as HTMLInputElement;
JlnPahangCB.addEventListener('change', handleCheckboxJlnPahang);


const handleCheckboxSempMukim = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[3].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[3].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const sempMukimCB = document.getElementById('SempMukim_CB') as HTMLInputElement;
sempMukimCB.addEventListener('change', handleCheckboxSempMukim);

const handleCheckboxKampung = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[4].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[4].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const kampungCB = document.getElementById('Kampung_CB') as HTMLInputElement;
kampungCB.addEventListener('change', handleCheckboxKampung);

// const handleCheckboxMulaKoordinatJKR = (event: Event) => {
//   const checkbox = event.target as HTMLInputElement;
//   // Add your condition here
//   if (checkbox.checked) {
//     console.log('Checkbox is checked');
//     Layers[5].setVisible(true);
//     // Do something when the checkbox is checked
//   } else {
//     console.log('Checkbox is unchecked');
//     Layers[5].setVisible(false);
//     // Do something when the checkbox is unchecked
//   }

// }

// // Attach the event listener to the checkbox
// const koordinatMulaJKR_CB = document.getElementById('KoordinatMulaJKR_CB') as HTMLInputElement;
// koordinatMulaJKR_CB.addEventListener('change', handleCheckboxMulaKoordinatJKR);


// HANDLE CHECKBOX KOORDINAT MULA JKR & JPS
const handleCheckboxMulaKoordinatJKRandJPS = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    Layers[5].setVisible(true); // JKR
    Layers[9].setVisible(true); // JPS
    // Do something when the checkbox is checked
  } else {
    Layers[5].setVisible(false); // JKR
    Layers[9].setVisible(false); // JPS
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const koordinatMulaJKRandJPS_CB = document.getElementById('KoordinatMulaJKRandJPS_CB') as HTMLInputElement;
koordinatMulaJKRandJPS_CB.addEventListener('change', handleCheckboxMulaKoordinatJKRandJPS);

// HANDLE CHECKBOX KOORDINAT AKHIR JKR & JPS
const handleCheckboxAkhirKoordinatJKRandJPS = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[10].setVisible(true); // JKR
    Layers[11].setVisible(true); // JPS
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[10].setVisible(false); // JKR
    Layers[11].setVisible(false); // JPS
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const koordinatAkhirJKRandJPS_CB = document.getElementById('KoordinatAkhirJKRandJPS_CB') as HTMLInputElement;
koordinatAkhirJKRandJPS_CB.addEventListener('change', handleCheckboxAkhirKoordinatJKRandJPS);


// HANDLE CHECKBOX KOORDINAT AKHIR JKR
// const handleCheckboxAkhirKoordinatJKR = (event: Event) => {
//   const checkbox = event.target as HTMLInputElement;
//   // Add your condition here
//   if (checkbox.checked) {
//     console.log('Checkbox is checked');
//     Layers[10].setVisible(true);
//     // Do something when the checkbox is checked
//   } else {
//     console.log('Checkbox is unchecked');
//     Layers[10].setVisible(false);
//     // Do something when the checkbox is unchecked
//   }

// }

// // Attach the event listener to the checkbox
// const koordinatAkhirJKR_CB = document.getElementById('KoordinatAkhirJKR_CB') as HTMLInputElement;
// koordinatAkhirJKR_CB.addEventListener('change', handleCheckboxAkhirKoordinatJKR);


const handleCheckboxJlnJKR = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[6].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[6].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}
// Attach the event listener to the checkbox
const JlnJKR_CB = document.getElementById('JlnJKR_CB') as HTMLInputElement;
JlnJKR_CB.addEventListener('change', handleCheckboxJlnJKR);

// const handleCheckboxMulaKoordinatJPS = (event: Event) => {
//   const checkbox = event.target as HTMLInputElement;
//   // Add your condition here
//   if (checkbox.checked) {
//     console.log('Checkbox is checked');
//     Layers[9].setVisible(true);
//     // Do something when the checkbox is checked
//   } else {
//     console.log('Checkbox is unchecked');
//     Layers[9].setVisible(false);
//     // Do something when the checkbox is unchecked
//   }

// }

// Attach the event listener to the checkbox
// const koordinatMulaJPS_CB = document.getElementById('KoordinatMulaJPS_CB') as HTMLInputElement;
// koordinatMulaJPS_CB.addEventListener('change', handleCheckboxMulaKoordinatJPS);

// const handleCheckboxAkhirKoordinatJPS = (event: Event) => {
//   const checkbox = event.target as HTMLInputElement;
//   // Add your condition here
//   if (checkbox.checked) {
//     console.log('Checkbox is checked');
//     Layers[11].setVisible(true);
//     // Do something when the checkbox is checked
//   } else {
//     console.log('Checkbox is unchecked');
//     Layers[11].setVisible(false);
//     // Do something when the checkbox is unchecked
//   }

// }

// // Attach the event listener to the checkbox
// const koordinatAkhirJPS_CB = document.getElementById('KoordinatAkhirJPS_CB') as HTMLInputElement;
// koordinatAkhirJPS_CB.addEventListener('change', handleCheckboxAkhirKoordinatJPS);

const handleCheckboxJlnJPS = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  // Add your condition here
  if (checkbox.checked) {
    console.log('Checkbox is checked');
    Layers[7].setVisible(true);
    // Do something when the checkbox is checked
  } else {
    console.log('Checkbox is unchecked');
    Layers[7].setVisible(false);
    // Do something when the checkbox is unchecked
  }

}

// Attach the event listener to the checkbox
const JlnJPS_CB = document.getElementById('JlnJPS_CB') as HTMLInputElement;
JlnJPS_CB.addEventListener('change', handleCheckboxJlnJPS);


const handleOptionOSM = (event: Event) => {

  const option = event.target as HTMLInputElement;
  if (option.checked) {
    const source_osm = new XYZ({
      url: configURL[2],
  })

    setImageryChecked(false);
    setOSMChecked(true);

    Layers[0].setSource(source_osm);

    // updateLayers.push(Layers[0], Layers[1], Layers[2], Layers[3], Layers[4], Layers[5], Layers[6], Layers[7]);
    // mapInit.addLayer(Layers[0]);

  } else {
    console.log("Not check")
  }
  
  // setImageryChecked(false);
  // setOSMChecked(true);
  // console.log("1");

}

const osm_option = document.getElementById('osm_basemap') as HTMLInputElement;
osm_option.addEventListener('click', handleOptionOSM);

const handleOptionImagery = (event: Event) => {
  const option = event.target as HTMLInputElement;
  console.log(option.checked);
  if (option.checked) {
    
    const source_imagery = new XYZ({
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  })

    setImageryChecked(true);
    setOSMChecked(false);

    Layers[0].setSource(source_imagery);

    // updateLayers.push(Layers[9], Layers[1], Layers[2], Layers[3], Layers[4], Layers[5], Layers[6], Layers[7]);
    // mapInit.addLayer(Layers[9]);

  } else {
    console.log("Not check")
  }
   
  // setOSMChecked(false);
  // setImageryChecked(true);
  // console.log("2");

}

const imagery_option = document.getElementById('imagery_basemap') as HTMLInputElement;
imagery_option.addEventListener('click', handleOptionImagery);
     
const onchangeOSM = () => {
  const params = new URLSearchParams(window.location.search);
  setImageryChecked(false);
  setOSMChecked(true);
 //  mapInit.setLayers()

 }

 const onchangeImagery = () => {

   setOSMChecked(false);
   setImageryChecked(true);
   
 } 

    }, []); // Empty dependency array ensures the effect runs once after the initial render


    // const params = new URLSearchParams(window.location.search);

    // console.log(params.get('x'));

    // mapInit.
    
    // var showGeneralSearchBar = false;
   
    

    var counterValueGS = 0;

    function onClickGeneralSearch() {
      counterValueGS += 1;
      console.log(counterValueGS);
      const generalSearchBar = document.getElementById('generalSearchBar') as HTMLInputElement;
      const btnSearchGeneralSearch = document.getElementById('searchButtonGeneralSearch') as HTMLInputElement;
      const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
      
      if (counterValueGS === 1) {
      generalSearchBar.style.display = "block";
      btnSearchGeneralSearch.style.display = "block";
     } else if(counterValueGS === 2) {
      generalSearchBar.style.display = "none";
      btnSearchGeneralSearch.style.display = "none";
      resultGeneralSearchBarDiv.style.display = "none";
      counterValueGS -= 2;
     }
      // showGeneralSearchBar = true;
      // setDisplaySearchBar = true;
      // if (generalSearchBar.style.display === "none") {
      //     generalSearchBar.style.display = "block";
      // } else { 
      //     generalSearchBar.style.display = "none";
      // }

      
      

    }

  

    var counterValueLayerBtn = 0;
    function onClickLayerButton() {

      counterValueLayerBtn += 1;
      console.log(counterValueLayerBtn);
      const legendBox = document.getElementById('LegendBox') as HTMLDivElement;
    
      
      if (counterValueLayerBtn === 1) {
        legendBox.style.display = "block";
     } else if(counterValueLayerBtn === 2) {
      legendBox.style.display = "none";
      counterValueLayerBtn -= 2;
     }


    }

    var counterValueAS = 0
    function onClickAdvanceSearch() {
      counterValueAS += 1;
      console.log(counterValueAS);
      const advanceSearchBox = document.getElementById('advanceSearchBox') as HTMLInputElement;

      
      if (counterValueAS === 1) {
        advanceSearchBox.style.display = "block";
     } else if(counterValueAS === 2) {
      advanceSearchBox.style.display = "none";
      counterValueAS -= 2;
     }


    }
    
    // if (setDisplaySearchBar === false) {

    //   const generalSearchBar = document.getElementById('generalSearchBar') as HTMLInputElement;
    //   generalSearchBar.style.display = "none";

    // } else {
    //   const generalSearchBar = document.getElementById('generalSearchBar') as HTMLInputElement;
    //   generalSearchBar.style.display = "block";

    // }




    var jkr_and_jps_centroid_data;
    // var get_features_data;
    var checker_val = 0;
    var features_data = [];
    const getData = async () => {
      try {
        // Replace the URL with your JSON API endpoint
        // const response = await fetch(configURL[1]+'/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKRandJPS_Roadline&outputFormat=application%2Fjson')
        // const response = await fetch(configURL[1]+'/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3Ajkrandjps_centroid&outputFormat=application%2Fjson');
        const response = await fetch(configURL[16]);
        const data: Item[] = await response.json();
        jkr_and_jps_centroid_data = data;

        for (let i = 0; i < jkr_and_jps_centroid_data["features"].length; i++) {
          delete jkr_and_jps_centroid_data["features"][i]["geometry"];
          delete jkr_and_jps_centroid_data["features"][i]["geometry_name"];
          delete jkr_and_jps_centroid_data["features"][i]["type"];
        }
        // console.log(jkr_and_jps_centroid_data["features"]);
        features_data = jkr_and_jps_centroid_data["features"];
        checker_val = checker_val + 1;
     
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getData();




    function generalSearch() {

      // const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;

      const searchKeyword = document.getElementById('generalSearchBar') as HTMLInputElement;
  
      const value = searchKeyword.value;
      var my_json = JSON.stringify(jkr_and_jps_centroid_data)
      var target_filter = value;

      // Function Sentence Case
      function toTitleCase(str) {
        return str.replace(
          /\w\S*/g,
          text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
      }

      // if (target_filter.includes(" ") === true) {
         
        target_filter = toTitleCase(target_filter);
        // console.log("Converted: "+target_filter);
         
      // }
      
      var filter_result = features_data.filter(({ properties: {NAMA_JALAN}}) => NAMA_JALAN.includes(target_filter) ); 
      // console.log(filter_result);

      // console.log(value);
      // const closeButtonGS = "<button id='closeButtonGeneralSearch'>x</button>" 
      // const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
      // function closeButtonGeneralSearch() {
      //   resultGeneralSearchBarDiv.style.display = 'none'
      //  }

      // document.getElementById('closeButtonGeneralSearch').addEventListener("click",closeButtonGeneralSearch);
      
     


      displayGeneralSearchResult(value);




      function displayGeneralSearchResult(kw) {
      

  
        const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
        var content = "";
        resultGeneralSearchBarDiv.style.display = 'none';

        // console.log(filter_result.length);

        if (kw !== '') {
        // content = "<option onclick='zoomTo(kw)'>Kampung Asap 2 "+kw+"</option>"
        resultGeneralSearchBarDiv.style.display = 'block';

        console.log(filter_result.length);

        if (filter_result.length === 0) {

          content += "<span> Tiada Rekod Ditemui <button id='closeButtonResultGS'>x</button></span>";
          

        } else {

          for (var key in filter_result) {
            if (filter_result.hasOwnProperty(key)) {
  
              // var result_loc = "http://localhost:3000?x="+filter_result[key].properties.Longitude+"&y="+filter_result[key].properties.Latitude;
              var result_loc = configURL[0]+"?x="+filter_result[key].properties.LONGITUDE+"&y="+filter_result[key].properties.LATITUDE+"&nj="+filter_result[key].properties.NAMA_JALAN;
  
              // content += "<style>option:hover {background-color: #8cc7f5;}</style><option className='resultItem' id='resultItem' onClick='location.href = `"+result_loc+"`'>"+filter_result[key].properties.Official_n+"</option>";
              content += "<style>option:hover {background-color: #8cc7f5;}</style><option className='resultItem' id='resultItem' onClick='location.href = `"+result_loc+"`'>"+filter_result[key].properties.NAMA_JALAN+"</option>";
            }
          }

        }
        
        


        } else {
        content += "";
        } 

        // console.log(content);\



        
        
        // content = closeButtonGS+content
        
        return resultGeneralSearchBarDiv.innerHTML =  content;

       

      }

      if (filter_result.length === 0) {
        document.getElementById('closeButtonResultGS').addEventListener("click",closeButtonResult);
      }

    }


    const closeButtonResult = () => {

      const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
      resultGeneralSearchBarDiv.style.display = 'none';
  
      // const attributeInfoCloseButton = document.getElementById('attributeInfoCloseButton') as HTMLElement
      // attributeInfoCloseButton.style.display = 'none';
  
     }

     


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

 
      
      const { value } = event.target;
        // setSearchTerm(value);
        console.log(value);
        console.log(jkr_and_jps_centroid_data);
    

        //We do that to ensure to get a correct JSON
        var my_json = JSON.stringify(jkr_and_jps_centroid_data)


        
        // console.log(kampung_data);
        console.log(features_data);

        // test filter 
        // var target_filter = "Kg. Asap";
        var target_filter = value;
        // var filter_result = features_data.filter(({ properties: {Official_n}}) => Official_n.includes(target_filter) );   
        var filter_result = features_data.filter(({ properties: {NAMA_JALAN}}) => NAMA_JALAN.includes(target_filter) );   
        console.log(filter_result);

        displayGeneralSearchResult(value);

        

        function displayGeneralSearchResult(kw) {

  
          const resultGeneralSearchBarDiv = document.getElementById('resultGeneralSearchBar') as HTMLElement;
          var content = "";
          resultGeneralSearchBarDiv.style.display = 'none';

          if (kw !== '') {
          // content = "<option onclick='zoomTo(kw)'>Kampung Asap 2 "+kw+"</option>"
          resultGeneralSearchBarDiv.style.display = 'block';
          
          for (var key in filter_result) {
            if (filter_result.hasOwnProperty(key)) {

              // var result_loc = "http://localhost:3000?x="+filter_result[key].properties.Longitude+"&y="+filter_result[key].properties.Latitude;
              var result_loc = configURL[1]+"?x="+filter_result[key].properties.LONGITUDE+"&y="+filter_result[key].properties.LATITUDE;

              // content += "<style>option:hover {background-color: #8cc7f5;}</style><option className='resultItem' id='resultItem' onClick='location.href = `"+result_loc+"`'>"+filter_result[key].properties.Official_n+"</option>";
              content += "<style>option:hover {background-color: #8cc7f5;}</style><option className='resultItem' id='resultItem' onClick='location.href = `"+result_loc+"`'>"+filter_result[key].properties.NAMA_JALAN+"</option>";
            }
          }

          } else {
          content += "";
          }

          // console.log(content);

      
          return resultGeneralSearchBarDiv.innerHTML = content;
 


        }



        // const selectItem = document.getElementById('resultItem') as HTMLElement;
        // selectItem.addEventListener('click', function() {
          
        //   var val = document.getElementById('resultItem');
        //   var item = val.value;
        // } 
        
        // );
    

        
        // var filtered_json = find_in_object(JSON.parse(my_json), {properties:{Official_n:value}});

        // function find_in_object(my_object, my_criteria){

        //   return my_object.filter(function(obj) {
        //     return Object.keys(my_criteria).every(function(c) {
        //       return obj[c] == my_criteria[c];
        //     });
        //   });
        
        // }

        // console.log(filtered_json);
        

      };


      function gotoAdvanceSearch() {
       

        // useEffect(() => {

        //   const mapInit = "";

        //  }, []);

        window.location.href = "/AdvanceSearch";

        console.log("gotoAdvanceSearch");

      }

      const tooltipLayer = (
        <Tooltip id="tooltip">
          <label>Lapisan Data</label>
        </Tooltip>
      );

      const tooltipGeneralSearch = (
        <Tooltip id="tooltipGS">
          <label>Carian Umum</label>
        </Tooltip>
      );

      const tooltipAdvanceSearch = (
        <Tooltip id="tooltipAS">
          <label>Carian Terperinci</label>
        </Tooltip>
      );

      const tooltipUserManual = (
        <Tooltip id="tooltipUM">
          <label>Manual Pengguna</label>
        </Tooltip>
      );


      const logo = require('../img/pahangroad-logo.png');

    // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
    //  const { value } = event.target;   
    //  console.log(value);
    //  setSearchTerm(value);

    // }

    return (
        
    <div id='mapDiv' className='map'>


    <div className='Container containerStyle'>
      <RoadMap className='roadMapStyle'></RoadMap>
      <span className='titleStyle'>Pahang Road Information</span>
      </div>
        
    {/* {isLegendVisible && } */}
    <div className='LegendBox' id='LegendBox'>

    <Form className='LayerForm'>

   <div className="mb-3">
      {/* <Form.Check type='checkbox' checked={!isJlnPersekutuanChecked} onChange={() => setJlnPersekutuanChecked((prev) => !prev)} id='JlnPersekutuanCB' label='Jalan Persekutuan'/> */}
    
    <input type='checkbox' checked={!isJlnPersekutuanChecked} onChange={() => setJlnPersekutuanChecked((prev) => !prev)} id='JlnPersekutuanCB'/>
    &nbsp;<label className='JlnPersekutuanIcon'></label>&nbsp;
    <label>Jalan Persekutuan</label>
    </div>

    <div className="mb-3">
      <input type='checkbox' checked={!isJlnPahangChecked} onChange={() => setJlnPahangChecked((prev) => !prev)} id='JlnPahang_CB'/>
    &nbsp;<label className='JlnPahangIcon'></label>&nbsp;
    <label>Jalan Negeri Pahang</label>
    
    </div>

    <div className="mb-3">
      
      <input type='checkbox' checked={!isSempMukimChecked} onChange={() => setSempMukimChecked((prev) => !prev)} id='SempMukim_CB'/>
    &nbsp;<label className='SempMukimIcon'></label>&nbsp;
    <label>Sempadan Mukim</label>
   
    </div>

    <div className="mb-3">
      <input type='checkbox' checked={!isKampungChecked} onChange={() => setKampungChecked((prev) => !prev)} id='Kampung_CB'/>
    &nbsp;<label className='KampungIcon'>abc</label>&nbsp;
    <label>Kampung</label>
    </div>

    {/* <div className="mb-3">
      <input type='checkbox' checked={!isKoordinatMulaJKRChecked} onChange={() => setKoordinatMulaJKRChecked((prev) => !prev)} id='KoordinatMulaJKR_CB'/>
    &nbsp;<label className='KoordinatMulaJalanJKRIcon'></label>&nbsp;
    <label>Koordinat Mula Jalan JKR</label>
    </div>

    <div className="mb-3">
      <input type='checkbox' checked={!isKoordinatAkhirJKRChecked} onChange={() => setKoordinatAkhirJKRChecked((prev) => !prev)} id='KoordinatAkhirJKR_CB'/>
    &nbsp;<label className='KoordinatAkhirJalanJKRIcon'></label>&nbsp;
    <label>Koordinat Akhir Jalan JKR</label>
    </div> */}

    <div className="mb-3">
      <input type='checkbox' checked={!isKoordinatMulaJKRandJPSChecked} onChange={() => setKoordinatMulaJKRandJPSChecked((prev) => !prev)} id='KoordinatMulaJKRandJPS_CB'/>
    &nbsp;<label className='KoordinatMulaJalanJKRandJPSIcon'></label>&nbsp;
    <label>Koordinat Mula Jalan JKR/JPS</label>
    </div>
    
    <div className="mb-3">
      <input type='checkbox' checked={!isKoordinatAkhirJKRandJPSChecked} onChange={() => setKoordinatAkhirJKRandJPSChecked((prev) => !prev)} id='KoordinatAkhirJKRandJPS_CB'/>
    &nbsp;<label className='KoordinatAkhirJalanJKRandJPSIcon'></label>&nbsp;
    <label>Koordinat Akhir Jalan JKR/JPS</label>
    </div>
    
    <div className="mb-3">
      <input type='checkbox' checked={!isJlnJKRChecked} onChange={() => setJlnJKRChecked((prev) => !prev)} id='JlnJKR_CB'/>
    &nbsp;<label className='JlnKampungJKRIcon'></label>&nbsp;
    <label>Jalan Kampung JKR</label>
    </div>

    {/* <div className="mb-3">
      <input type='checkbox' checked={!isKoordinatMulaJPSChecked} onChange={() => setKoordinatMulaJPSChecked((prev) => !prev)} id='KoordinatMulaJPS_CB'/>
    &nbsp;<label className='KoordinatMulaJalanJPSIcon'></label>&nbsp;
    <label>Koordinat Mula Jalan JPS</label>
    </div> */}

    {/* <div className="mb-3">
      <input type='checkbox' checked={!isKoordinatAkhirJPSChecked} onChange={() => setKoordinatAkhirJPSChecked((prev) => !prev)} id='KoordinatAkhirJPS_CB'/>
    &nbsp;<label className='KoordinatAkhirJalanJPSIcon'></label>&nbsp;
    <label>Koordinat Akhir Jalan JPS</label>
    </div> */}

    <div className="mb-3">
        <input type='checkbox' checked={!isJlnJPSChecked} onChange={() => setJlnJPSChecked((prev) => !prev)} id='JlnJPS_CB'/>
      &nbsp;<label className='JlnPertanianJPS'></label>&nbsp;
      <label>Jalan Pertanian JPS</label>
    </div>

    <label><b>Basemap</b></label>

    <div className="mb-2">
        <input type='radio' checked={isOSMChecked} id='osm_basemap'/>
      <span> Openstreetmap</span>
    </div>

    <div className="mb-2">
        <input type='radio' checked={isImageryChecked} id='imagery_basemap'/>
      <span> World Imagery</span>
    </div>

    </Form>

    

    {/* <input type='checkbox' checked={!isChecked} onChange={() => setIsChecked((prev) => !prev)} id='myCB'></input>
    <label>Pahang Boundary</label> */}
    <br></br>

    </div>

    <OverlayTrigger placement="left" overlay={tooltipLayer}> 
    <button className='LayerButton' id='buttonLegend' onClick={onClickLayerButton}>
    <Fragment>
    <LayerIcon className='layerIcon'/>
    </Fragment>
    </button>
    </OverlayTrigger>
   
    <OverlayTrigger placement="right" overlay={tooltipGeneralSearch}> 
   <button className='generalSearch' id='buttonGeneralSearch' onClick={onClickGeneralSearch}>
   <Fragment>
    <Binoculars className='binocularsIcon' />
   </Fragment>
   </button>
   </OverlayTrigger>

   {/* <div className='blankSpace'>Mari kita ke ladang</div> */}
 
  
   {/* <input id='generalSearchBar' type='text' placeholder='Nama jalan' className='generalSearchBar'/>


   <button className='searchButtonGeneralSearch' id='searchButtonGeneralSearch' onClick={generalSearch}>
   <Fragment>
    <SearchOutline className='searchOutlineIcon'/>
   </Fragment>
   </button> */}

   <div className='generalSearchContainer'>
   <table>
    <tr>
      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td>

        <input id='generalSearchBar' type='text' placeholder='Nama jalan' className='generalSearchBar'/>
        
      </td>
      <td>

      <button className='searchButtonGeneralSearch' id='searchButtonGeneralSearch' onClick={generalSearch}>
      <Fragment>
      <SearchOutline className='searchOutlineIcon'/>
      </Fragment>

      </button>
      </td>
    </tr>
   </table>
   </div>



   {/* <button className='advanceSearch' id='buttonAdvanceSearch' onClick={onClickAdvanceSearch}>
   <Fragment>
    <Search/>
   </Fragment>
   </button> */}
   <OverlayTrigger placement="right" overlay={tooltipAdvanceSearch}>
   <button className='advanceSearch' onClick={gotoAdvanceSearch}>
    <Fragment><Search className='searchIcon'/></Fragment></button>
    </OverlayTrigger>
   

   
   <a href={Pdf} rel="noopener noreferrer" target="_blank">
   <OverlayTrigger placement="right" overlay={tooltipUserManual}>
   <button className='manual' id='buttonManual'>
   <Fragment>
    <Book className='bookIcon' />
   </Fragment>
   </button>
   </OverlayTrigger>
   </a>
   
   
   {/* <ul>
        {searchResults.map(item => (
          <h4>{item.name}</h4>
        ))}
    </ul> */}
    <div className='resultGeneralSearchBar' id='resultGeneralSearchBar'></div>

    <div className='advanceSearchBox' id='advanceSearchBox'><label>Advance Search Box</label></div>

   <div id='attributesInfoBox' className='attributesInfoBox'>
   
   {/* <table id='attributeInfoCloseButton' className='attributeInfoCloseButton'>
    <tr><td colSpan={2}>Maklumat Atribut
        <div  onClick={closeAttributeInfo}>&nbsp;
        <Fragment><CloseCircle className='closeIcon'/></Fragment>
        </div>
        </td>
    </tr>
    </table> */}
   
    
    {/* <span className='InfoAttribute'>Maklumat Atribut</span> */}
    

    {/* <pre id='formatted-json'></pre> */}
    {/* <div id='headerAttInfo'></div> */}
    <div id='attributeInfo'></div>
   </div>

   

    </div>
    );
  };
  
  export default MapComponent;