import React from 'react';
import { Component, useState, useEffect  } from 'react';
import './AdvanceSearch.css';
import "bootstrap/dist/css/bootstrap.css";
import { Button, Container, Col, Row, Table, Tooltip, OverlayTrigger} from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
// import TABLE_BODY from "./data.json";
import TABLE_BODY from "./JKRnJPS_data.json";
// import { setTimeout } from 'timers/promises';
import AdvanceSearchTest from './AdvanceSearchTest';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Alert from 'react-bootstrap/Alert';
import { FontSize, LineHeight } from 'styled-icons/remix-editor';
import Spinner from 'react-bootstrap/Spinner';
import './Poppins/Poppins-Medium.ttf';
// import Poppins from './Poppins'
import { font } from "./font";
import {configURL} from '../config/config'
import Badge from 'react-bootstrap/Badge';

interface Item {
  id: number;
  name: string;
}

interface allItem {
  id: number;
  name: string;
}



// type ArrayElementType = typeof TABLE_BODY[number] & {
//   button: any;
// };

// var JKRandJPS_Roadline;

const AdvanceSearch: React.FC = () => {
  const [roadlineData, setRoadlineData] = useState([]);
  const [resultMessage, setResultMessage] = useState('Menunjukkan 50 rekod pertama');
  const [isMessageDisplay, setMessageDisplay] = useState(false);
  // const [messageContent, setMessageContent] = useState('');
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  const [allRoadlineData, setAllRoadlineData] = useState([]);
  const [daerah, setDaerah] = useState([]);
  const [selectedDaerah, setSelectedDaerah] = useState('');
  const [kategori, setKategori] = useState([]);

  const [selectedKategori, setSelectedKategori] = useState('');
  const [tahun, setTahun] = useState([]);

  const [selectedTahun, setSelectedTahun] = useState('')
  const [mukim, setMukim] = useState([]);
  const [selectedMukim, setSelectedMukim] = useState('')
  const [kampung, setKampung] = useState([]);
  const [selectedKampung, setSelectedKampung] = useState('');
  const [ladang, setLadang] = useState([]);
  const [selectedLadang, setSelectedLadang] = useState('');
  const [projek, setProjek] = useState([]);
  const [namaJalan, setNamaJalan] = useState([]);
  const [selectedJalan, setSelectedJalan] = useState('');
  const [selectedProjek, setSelectedProjek] = useState('');
  const [kategoriJalan, setKategoriJalan] = useState([]);
  const [selectedKategoriJalan, setSelectedKategoriJalan] = useState('');
  const [permukaanJalan, setPermukaanJalan] = useState([]);
  const [selectedPermukaanJalan, setSelectedPermukaanJalan] = useState('');
  const [lebarJalan, setLebarJalan] = useState<number | ''>('');
  const [panjangJalan, setPanjangJalan] = useState<number | ''>('');

  const [searchData, setsearchData] = useState([]);
  const [lessThanLebar, setLessThanLebar] = useState('<');
  const [greaterThanLebar, setGreaterThanLebar] = useState('>');

  const [lessThanPanjang, setLessThanPanjang] = useState('Kurang dari (<)');
  const [greaterThanPanjang, setGreaterThanPanjang] = useState('Lebih dari (>)');
  

useEffect(() => {

var roadline_data_array = [];
var all_roadline_data_array = [];
// var roadline_data;

const getData = async () => {
  
  try {
    // Replace the URL with your JSON API endpoint
    // const response = await fetch(configURL[1]+'/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKRandJPS_Roadline&maxFeatures=50&outputFormat=application%2Fjson')
    const response = await fetch(configURL[14]);
    // const response = await fetch('http://localhost:8080/geoserver/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKRandJPS_Roadline&outputFormat=application%2Fjson');
    const data: Item[] = await response.json();

    for (let i = 0; i < data["features"].length; i++) {
    
      roadline_data_array.push(data["features"][i].properties);

    
    }
  

    setRoadlineData(roadline_data_array);

  
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

getData();

const spinner = async (val:any) => {

  function stopLoading() {
    console.log("stop spinner");
    setLoadingSpinner(false);
    setMessageDisplay(true);
  }
  
  const loadingTimeout = setTimeout(() => {
    stopLoading();
  }, val);

  return () => clearTimeout(loadingTimeout);
}

spinner(10000);

const getAllRoadlineData = async () => {

  console.log("getAllRoadlineData");
  
  try {
    // Replace the URL with your JSON API endpoint
    // const response_all = await fetch(configURL[1]+'/pahang_road/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=pahang_road%3AJKRandJPS_Roadline&outputFormat=application%2Fjson');
    const response_all = await fetch(configURL[15]);
    const alldata: allItem[] = await response_all.json();

    for (let i = 0; i < alldata["features"].length; i++) {
      all_roadline_data_array.push(alldata["features"][i].properties);
    }

    setAllRoadlineData(all_roadline_data_array);
  } catch (error) {
    console.error('Error fetching data:', error);
  }

  console.log(all_roadline_data_array);
  setsearchData(allRoadlineData);

};

const timeoutId = setTimeout(() => {
  getAllRoadlineData();
}, 1000);

return () => clearTimeout(timeoutId);


},[]); // Empty dependency array ensures the effect runs once after the initial render  


// const convertValuesToStrings = (arr) => {
//   return arr.map((item) => {
//     // If the item is an object or array, recursively convert its values
//     if (typeof item === 'object' && item !== null) {
//       return convertValuesToStrings(item);
//     }

//     // Convert other data types to strings
//     return String(item);
//   });
// };


const downloadPDF = () => {

// const stringifiedArray = convertValuesToStrings(searchData);

// const myJSON = JSON.stringify(searchData);
// var newArr = [];
// newArr.push(myJSON);
var data_array = [];

for (let index = 0; index < searchData.length; index++) {

  // data_array.push(searchData[index].DAERAH, searchData[index].TAHUN.toString(), searchData[index].LEBAR_JALA.toString(), searchData[index].PANJANG_JA.toString());
  data_array.push(searchData[index].DAERAH);
  data_array.push(searchData[index].TAHUN.toString());
  data_array.push(searchData[index].LEBAR_JALA.toString());
  data_array.push(searchData[index].PANJANG_JA.toString());
  data_array.push(searchData[index].KATEGORI);
  
}

console.log(data_array);
// Set column headers
// const headers = ['DAERAH', 'KATEGORI', 'TAHUN', 'MUKIM', 'DUN', 'NAMA_KAMPU', 'NAMA_LADAN', 'PROJEK', 'NAMA_JALAN', 'KOD_JALAN', 'ZON', 'KATEGORI_J', 'SRT', 'SRT_KOD', 'LEBAR_JALA', 'PANJANG_JA', 'AUTHORITY', 'SURVYD_BY', 'TARIKH_KEM'];

// const config = {
//   printHeaders:true,
//   autoSize: false,
//   fontSize:5
// };


var doc = new jsPDF('landscape', 'px', 'a4', true);


autoTable(doc, { 
  html: '#dataTableAdvanceSearch',
  theme: 'striped',
  styles: { fontSize: 5},
  columnStyles: {
    0: {cellWidth: 20}, // #
    1: {cellWidth: 22}, // Daerah
    2: {cellWidth: 22}, // Kategori
    3: {cellWidth: 20}, // Dun
    4: {cellWidth: 20}, // Mukim
    5: {cellWidth: 20}, // Kod Jalan
    6: {cellWidth: 20}, // ID Jalan
    7: {cellWidth: 60}, // Nama Kampung
    8: {cellWidth: 55}, // Nama Ladang
    9: {cellWidth: 30}, // Nama Projek
    10: {cellWidth: 60}, // Nama Jalan  
    11: {cellWidth: 25}, // Panjang Jalan(km)
    12: {cellWidth: 25}, // Purata Lebar Jalan(m)  
    13: {cellWidth: 18}, // SRT (KOD)   
    14: {cellWidth: 20}, // SRT  
    15: {cellWidth: 20}, // Tahun  
    16: {cellWidth: 25}, // Tarikh Kemaskini  
    17: {cellWidth: 20}, // Pihak Kuasa 
    18: {cellWidth: 25}, // Kategori Jalan 
    19: {cellWidth: 25}, // Pengurusan 
    20: {cellWidth: 25}, // Pengukuran 
  } 

})
  // doc.setFont("Poppins");
  // doc.addFileToVFS("WorkSans-normal.ttf", font);
  // doc.addFont("WorkSans-normal.ttf", "WorkSans", "normal");
  // doc.setFont("WorkSans");
  // doc.text(searchData[0].TAHUN.toString(), 100, 150);
  // doc.table(20,20, searchData, headers, config);
  // doc.autoTable();
  doc.save("download.pdf");

}

function goBack() {
  
  window.location.href = configURL[0];

}


    // Set List of daerah in drop down
  // const listDaerah = () => {
  //   var filtered_daerah = [];
  //   for (let i=0; i < allRoadlineData.length; i++) {
  //     filtered_daerah.push(allRoadlineData[i].DAERAH);
  //   }
  //   var daerahOutput = removeDuplicates(filtered_daerah); 
  //   setDaerah(daerahOutput)
  // }

  // listDaerah();

function clearDropDownList(handleType) {


switch (handleType) {
  case "handleDaerah":
    setKategori([]);
    setSelectedKategori("");

    setTahun([]);
    setSelectedTahun("");

    setMukim([]);
    setSelectedMukim("")
    
    setKampung([]);
    setSelectedKampung("");

    setLadang([]);
    setSelectedLadang("");

    setProjek([]);
    setSelectedProjek("");

    setNamaJalan([]);
    setSelectedJalan("");

    setKategoriJalan([]);
    setSelectedKategoriJalan("");

    setPermukaanJalan([]);
    setSelectedPermukaanJalan("");
    break;
  case "handleKategori":
    setTahun([]);
    setSelectedTahun("");

    setMukim([]);
    setSelectedMukim("")
    
    setKampung([]);
    setSelectedKampung("");

    setLadang([]);
    setSelectedLadang("");

    setProjek([]);
    setSelectedProjek("");

    setNamaJalan([]);
    setSelectedJalan("");

    setKategoriJalan([]);
    setSelectedKategoriJalan("");

    setPermukaanJalan([]);
    setSelectedPermukaanJalan("");
    break;
  case "handleTahun":
    setMukim([]);
    setSelectedMukim("")
    
    setKampung([]);
    setSelectedKampung("");

    setLadang([]);
    setSelectedLadang("");

    setProjek([]);
    setSelectedProjek("");

    setNamaJalan([]);
    setSelectedJalan("");

    setKategoriJalan([]);
    setSelectedKategoriJalan("");

    setPermukaanJalan([]);
    setSelectedPermukaanJalan("");
   break;
  case "handleMukim":
    setKampung([]);
    setSelectedKampung("");

    setLadang([]);
    setSelectedLadang("");

    setProjek([]);
    setSelectedProjek("");

    setNamaJalan([]);
    setSelectedJalan("");

    setKategoriJalan([]);
    setSelectedKategoriJalan("");

    setPermukaanJalan([]);
    setSelectedPermukaanJalan("");
   break;
  case "handleKampung":
    setLadang([]);
    setSelectedLadang("");

    setProjek([]);
    setSelectedProjek("");

    setNamaJalan([]);
    setSelectedJalan("");

    setKategoriJalan([]);
    setSelectedKategoriJalan("");

    setPermukaanJalan([]);
    setSelectedPermukaanJalan("");
   break;
   case "handleLadang":
    setProjek([]);
    setSelectedProjek("");

    setNamaJalan([]);
    setSelectedJalan("");

    setKategoriJalan([]);
    setSelectedKategoriJalan("");

    setPermukaanJalan([]);
    setSelectedPermukaanJalan("");
    break;
    case "handleProjek":
    setNamaJalan([]);
    setSelectedJalan("");

    setKategoriJalan([]);
    setSelectedKategoriJalan("");

    setPermukaanJalan([]);
    setSelectedPermukaanJalan("");
    break;
    case "handleJalan":
      setKategoriJalan([]);
      setSelectedKategoriJalan("");
  
      setPermukaanJalan([]);
      setSelectedPermukaanJalan("");
    break;
}


}



const handleDaerah = (event) =>  {

  // Clear drop down
  clearDropDownList("handleDaerah");

   // Update the state with the selected value
   setSelectedDaerah(event.target.value);
   var daerah = event.target.value;

  // Filter Kategori by Daerah 
   var filter_result = allRoadlineData.filter(({ DAERAH }) => DAERAH.includes(daerah) );  
   var filtered_kategori = [];



   for (let i=0; i < filter_result.length; i++) {
    if (filter_result[i].KATEGORI !== '') {
    filtered_kategori.push(filter_result[i].KATEGORI);
    }
   }
  

   var KategoriOutput = removeDuplicates(filtered_kategori);

  if (KategoriOutput.length < 1) {
   
    handleKategori(event, "listAll");
 
    } else {
 
      setKategori(KategoriOutput);
 
    }

   setsearchData(filter_result);

}

const handleKategori = (event, trigger) =>  {

  clearDropDownList("handleKategori");

    // Update the state with the selected value
    setSelectedKategori(event.target.value);

    var kategori = event.target.value;
    var filtered_tahun = [];
 
  // If daerah data null continue to list tahun in Tahun drop down
  if (trigger === "listAll") {
    
    for (let i=0; i < searchData.length; i++) {

      if (searchData[i].TAHUN !== '') {
        filtered_tahun.push(searchData[i].TAHUN);

      }
   
     }

    
   
  } else {

    // Filter Tahun by Kategori 
    var filter_result = searchData.filter(({ KATEGORI }) => KATEGORI.includes(kategori) ); 

    for (let i=0; i < filter_result.length; i++) {
      if (filter_result[i].TAHUN !== '') {
      filtered_tahun.push(filter_result[i].TAHUN);
      }
    }

  }

    var tahunOutput = removeDuplicates(filtered_tahun);

    if (tahunOutput.length < 1) {
    
      handleMukim(event, "listAll");
  
      } else {
      
      setTahun(tahunOutput);
  
      }


    
    setsearchData(filter_result);

}  

const handleTahun = (event) =>  {

  clearDropDownList("handleTahun");

  // Update the state with the selected value
  setSelectedTahun(event.target.value);

  var tahun = parseInt(event.target.value);

 // Filter Mukim by tahun 
  // var filter_result = allRoadlineData.filter(({ TAHUN }) => TAHUN.includes(tahun) );  
  var filter_result = [];  
  var filtered_mukim = [];


for (let i=0; i < searchData.length; i++) {

      if (searchData[i].TAHUN === tahun) {

        filtered_mukim.push(searchData[i].MUKIM);
        filter_result.push(searchData[i]);
        
      }

}


  var mukimOutput = removeDuplicates(filtered_mukim);

  setMukim(mukimOutput);
  setsearchData(filter_result);

  
}  

const handleMukim = (event, trigger) =>  {

  clearDropDownList("handleMukim");

   // Update the state with the selected value
   setSelectedMukim(event.target.value);

   var mukim = event.target.value;
   var filtered_mukim = [];
   var filtered_kampung = [];

   if (trigger === "listAll") { 

    for (let i=0; i < searchData.length; i++) {
    if (searchData[i].MUKIM !== '') {
      filtered_mukim.push(searchData[i].MUKIM);
    }
  } 
  var mukimOutput = removeDuplicates(filtered_mukim);
  setMukim(mukimOutput);

   } else {

    var filter_result = searchData.filter(({ MUKIM }) => MUKIM.includes(mukim) ); 
    for (let i=0; i < filter_result.length; i++) {
      
      if (filter_result[i].NAMA_KAMPU !== '') {
      filtered_kampung.push(filter_result[i].NAMA_KAMPU);
      }

     }

   }


   var namaKampungOutput = removeDuplicates(filtered_kampung);

   if (filtered_kampung.length < 1) { 

    handleKampung(event, "listAll");

    } else { 
     
    setKampung(namaKampungOutput);
    
     }


   
   setsearchData(filter_result);

}  

const handleKampung = (event, trigger) =>  {

  clearDropDownList("handleKampung");
  // Update the state with the selected value
  setSelectedKampung(event.target.value);

  var kampung = event.target.value;
  var filtered_nama_kampung = [];

 
  var filtered_ladang = [];


  if (trigger === "listAll") {

    for (let i=0; i < searchData.length; i++) {

      if (searchData[i].NAMA_KAMPU !== '') {
        filtered_nama_kampung.push(searchData[i].NAMA_KAMPU);
      }
   
     }
     
     var namaKampungOutput = removeDuplicates(filtered_nama_kampung);
     setKampung(namaKampungOutput);

  } else {
    // Filter nama ladang by nama kampung 
    var filter_result = searchData.filter(({ NAMA_KAMPU }) => NAMA_KAMPU.includes(kampung) ); 
    for (let i=0; i < filter_result.length; i++) {

      if (filter_result[i].NAMA_LADAN !== '') {
        filtered_ladang.push(filter_result[i].NAMA_LADAN);
      }
    }
  }

  var namaLadangOutput = removeDuplicates(filtered_ladang);

  if (filtered_ladang.length < 0) {

  handleLadang(event, "listAll");

  } else {
   
    setLadang(namaLadangOutput);

  }
  
  setsearchData(filter_result);

}  

const handleLadang = (event, trigger) =>  {

  clearDropDownList("handleLadang");

   // Update the state with the selected value
   setSelectedLadang(event.target.value);

   var ladang = event.target.value;
   var filtered_nama_ladang = [];


   if (trigger === "listAll") {

    for (let i=0; i < searchData.length; i++) {

      if (searchData[i].NAMA_LADAN !== '') {
        filtered_nama_ladang.push(searchData[i].NAMA_LADAN);
      }
   
     }
     
     var namaLadangOutput = removeDuplicates(filtered_nama_ladang);
     setLadang(namaLadangOutput);

   } else {

    // Filter nama projek by ladang 
   var filter_result = searchData.filter(({ NAMA_LADAN }) => NAMA_LADAN.includes(ladang) ); 
   var filtered_projek = [];
 
    for (let i=0; i < filter_result.length; i++) {
    
      

    if (filter_result[i].PROJEK !== '') {
      filtered_projek.push(filter_result[i].PROJEK);
      
    }
    
   }
  
  
   var projekOutput = removeDuplicates(filtered_projek);

   if (projekOutput.length < 1) {
    handleProjek(event, "listAll");
    } else { 
    setProjek(projekOutput);
    }
   
    setsearchData(filter_result);

   }
  
  
   
}  

const handleProjek = (event, trigger) =>  {

  clearDropDownList("handleProjek");

// Update the state with the selected value
    setSelectedProjek(event.target.value);
    var projek = event.target.value;
    var filtered_nama_jalan = [];

  if (trigger === "listAll") {
  
   for (let i=0; i < searchData.length; i++) {

   if (searchData[i].NAMA_JALAN !== '') {
     filtered_nama_jalan.push(searchData[i].NAMA_JALAN);
    
   }

  }
  } else {

  // Filter nama jalan by projek 
   var filter_result = searchData.filter(({ PROJEK }) => PROJEK.includes(projek) ); 

    for (let i=0; i < filter_result.length; i++) {
    
   

    if (filter_result[i].PROJEK !== '') {
      filtered_nama_jalan.push(filter_result[i].NAMA_JALAN);
    }
    
   }


  }

  var namaJalanOutput = removeDuplicates(filtered_nama_jalan);
  setNamaJalan(namaJalanOutput);

}  

const handleJalan = (event) =>  {

  clearDropDownList("handleJalan");

  // Update the state with the selected value
  setSelectedJalan(event.target.value);

  var jalan = event.target.value;

 // Filter nama jalan by nama projek 
  var filter_result = searchData.filter(({ NAMA_JALAN }) => NAMA_JALAN.includes(jalan) ); 
  var filtered_kategoti_jalan = [];

   for (let i=0; i < filter_result.length; i++) {
   if (filter_result[i].KATEGORI_J !== '') {
    filtered_kategoti_jalan.push(filter_result[i].KATEGORI_J);
   }
   
  }
 
  var kategoriJalanOutput = removeDuplicates(filtered_kategoti_jalan);

  
  if (kategoriJalanOutput.length < 1) {
   

   handleKategoriJalan(event, "listAll");

   } else {

   setKategoriJalan(kategoriJalanOutput);

   }

 
  setsearchData(filter_result);


}  

const handleKategoriJalan = (event, trigger) =>  {

  // Update the state with the selected value
  setSelectedKategoriJalan(event.target.value);
  var kategoriJalan = event.target.value;
  var filtered_kategori_jalan = [];
  var filtered_jenis_permukaan = [];

if (trigger === "listAll") {

 for (let i=0; i < searchData.length; i++) {

 if (searchData[i].KATEGORI_J !== '') {
  filtered_kategori_jalan.push(searchData[i].KATEGORI_J);
  
 }

}
var kategoriJalanOutput = removeDuplicates(filtered_kategori_jalan);
setKategoriJalan(kategoriJalanOutput);

} else {

// Filter jenis permukaan by kategori jalan 
 var filter_result = searchData.filter(({ KATEGORI_J }) => KATEGORI_J.includes(kategoriJalan) ); 

  for (let i=0; i < filter_result.length; i++) {
  
  if (filter_result[i].SRT !== '') {
    filtered_jenis_permukaan.push(filter_result[i].SRT);
  }
  
 }


}

var jenisPermukaanOutput = removeDuplicates(filtered_jenis_permukaan);

console.log(jenisPermukaanOutput);

console.log(jenisPermukaanOutput.length);

// if (jenisPermukaanOutput.length < 1) {
  setPermukaanJalan(jenisPermukaanOutput);
// }

setsearchData(filter_result);
console.log(searchData);

} 

const handleJenisPermukaan = (event) =>  {
 
  setSelectedPermukaanJalan(event.target.value);
  
}

const handleLebarJalan = (event: React.ChangeEvent<HTMLInputElement>) => {
  // Ensure that the value is a number or an empty string
  const newValue = event.target.value === '' ? '' : Number(event.target.value);

  setLebarJalan(newValue);
};


const handlePanjangJalan = (event: React.ChangeEvent<HTMLInputElement>) => {
  // Ensure that the value is a number or an empty string
  const newValue = event.target.value === '' ? '' : Number(event.target.value);

  setPanjangJalan(newValue);
};


function removeDuplicates(arr) {
  return arr.filter((item,index) => arr.indexOf(item) === index);
 }

function resetButton() {

  setSelectedDaerah("");

  setKategori([]);
  setSelectedKategori("");

  setTahun([]);
  setSelectedTahun("");

  setMukim([]);
  setSelectedMukim("")
  
  setKampung([]);
  setSelectedKampung("");

  setLadang([]);
  setSelectedLadang("");

  setProjek([]);
  setSelectedProjek("");

  setNamaJalan([]);
  setSelectedJalan("");

  setKategoriJalan([]);
  setSelectedKategoriJalan("");

  setPermukaanJalan([]);
  setSelectedPermukaanJalan("");

  setLebarJalan('');
  setPanjangJalan('');

  setsearchData(allRoadlineData);


}

// const search_spinner = async (val:any) => {

  

//   function stopLoading() {
//     console.log("stop spinner");
//     setLoadingSpinner(false);
//     // setMessageDisplay(true);
//   }
  
//   const loadingTimeout = setTimeout(() => {
//     stopLoading();
//   }, val);

//   return () => clearTimeout(loadingTimeout);
// }


// function search_spinner(val) {
  
//   function stopLoading() {
//     console.log("stop spinner");
//     setLoadingSpinner(false);
//     // setMessageDisplay(true);
//   }
  
//   const loadingTimeout = setTimeout(() => {
//     stopLoading();
//   }, val);

//   return () => clearTimeout(loadingTimeout);
  

// }

// function cariButton() {
 
//   search_spinner(5000); 

// }



function cariButton() {
  

  console.log(searchData);

  const dropDownLebarSelection = document.getElementById('lebarJalanCondition') as HTMLSelectElement;
  const dropDownPanjangSelection = document.getElementById('panjangJalanCondition') as HTMLSelectElement;
  const formLebar = document.getElementById('lebarJalan') as HTMLInputElement;
  const formPanjang = document.getElementById('panjangJalan') as HTMLInputElement;

  var lebar_operator = dropDownLebarSelection.value;
  var panjang_operator = dropDownPanjangSelection.value;

  // var lebarSelectionValue = parseFloat(dropDownLebarSelection.value);
  // var panjangSelectionValue = parseFloat(dropDownPanjangSelection.value);

  var lebarSelectionValue = 0;
  var panjangSelectionValue = 0;

  console.log(lebar_operator);
  console.log(panjang_operator);

  var lebarValue = parseFloat(formLebar.value);
  var panjangValue = parseFloat(formPanjang.value);

  if (lebar_operator === '<') {
     console.log("Lebar = <");
     lebarSelectionValue = 0;
  } else if (lebar_operator === '>') {
    console.log("Lebar = >");
     lebarSelectionValue = 1; 
  }
  
  if (panjang_operator === '<') {
    console.log("{Panjang = <");
    panjangSelectionValue = 0
  } else if (panjang_operator === '>') {
    console.log("{Panjang = >");
    panjangSelectionValue = 1
  }
  

  // SEARCH ALL EXCEPT LEBAR JALAN & PANJANG JALAN VALUES
  if (isNaN(lebarValue) === true && isNaN(panjangValue) === true) {


    
    setRoadlineData(searchData);
    var message = "Hasil Carian: "+searchData.length+" rekod ditemui";
  
    setResultMessage(message);
    setMessageDisplay(true);
    
  }
  // END - SEARCH ALL EXCEPT LEBAR JALAN & PANJANG JALAN VALUES


  var filter_result = [];

 // SEARCH ALL CONDITION
  const daerahDP = document.getElementById('daerahSelection') as HTMLSelectElement;
  const kategpriDP = document.getElementById('kategoriSelection') as HTMLSelectElement;
  const tahunDP = document.getElementById('tahunSelection') as HTMLSelectElement;
  const mukimDP = document.getElementById('mukimSelection') as HTMLSelectElement;
  const kampungDP = document.getElementById('kampungSelection') as HTMLSelectElement;
  const ladangDP = document.getElementById('ladangSelection') as HTMLSelectElement;
  const projekDP = document.getElementById('projekSelection') as HTMLSelectElement;
  const jalanDP = document.getElementById('namaJalanSelection') as HTMLSelectElement;
  const kategoriJalanDP = document.getElementById('kategoriJalanSelection') as HTMLSelectElement;
  const jenisPermukaanDP = document.getElementById('jenisPermukaanSelection') as HTMLSelectElement;

  var sum_value = parseInt(daerahDP.value) + parseInt(kategpriDP.value) + parseInt(tahunDP.value) + parseInt(mukimDP.value) + parseInt(kampungDP.value) + parseInt(ladangDP.value) + parseInt(projekDP.value) + parseInt(jalanDP.value) + parseInt(kategoriJalanDP.value) + parseInt(jenisPermukaanDP.value);
  
  console.log(parseInt(daerahDP.value));
  console.log(parseInt(kategpriDP.value));
  console.log(parseInt(tahunDP.value));
  console.log(parseInt(mukimDP.value));
  console.log(parseInt(kampungDP.value));
  console.log(parseInt(ladangDP.value));
  console.log(parseInt(projekDP.value));
  console.log(parseInt(jalanDP.value));
  console.log(parseInt(kategoriJalanDP.value));
  console.log(parseInt(jenisPermukaanDP.value));


  if (sum_value === 0) {

  
    console.log("Search all");
    var message = "Hasil Carian: "+allRoadlineData.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    filter_result = allRoadlineData
    setRoadlineData(filter_result);

  }
  // END SEARCH ALL CONDITION


  // OTHERS SEARCH CONDITION
  // 0 = Indicate Less than
  // 1 = Indicate Greater than
  // Lebar have value , panjang have no value
  console.log(lebarSelectionValue);
  console.log(isNaN(lebarValue));
  console.log(panjangSelectionValue);
  console.log(isNaN(panjangValue));
  if ((lebarSelectionValue === 0 && isNaN(lebarValue) === false) && (panjangSelectionValue === 0 && isNaN(panjangValue) === true)) {
    // Kurang dari (<)
    console.log("1"); 

    for (let i = 0; i < searchData.length; i++) {
      
      if (searchData[i].LEBAR_JALA < lebarValue) {         
        filter_result.push(searchData[i]);
      }

    }

    console.log(filter_result);
    // setsearchData(filter_result);
    message = "Hasil Carian: "+filter_result.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    setRoadlineData(filter_result);

  } else if ((lebarSelectionValue === 1 && isNaN(lebarValue) === false) && (panjangSelectionValue === 0 && isNaN(panjangValue) === true)) {
   // Lebih dari (<)
   console.log('2'); 
   for (let i = 0; i < searchData.length; i++) {
      
    if (searchData[i].LEBAR_JALA > lebarValue) {         
      filter_result.push(searchData[i]);
    }

  }

  console.log(filter_result);
  message = "Hasil Carian: "+filter_result.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    setRoadlineData(filter_result);

  
  }
  // Panjang have value , Lebar have no value
  if ((lebarSelectionValue === 0 && isNaN(lebarValue) === true) && (panjangSelectionValue === 0 && isNaN(panjangValue) === false)) {
    // Kurang dari (<)
    console.log('3');

    for (let i = 0; i < searchData.length; i++) {
      
      if (searchData[i].PANJANG_JA < panjangValue) {         
        filter_result.push(searchData[i]);
      }

    }

    console.log(filter_result);
    message = "Hasil Carian: "+filter_result.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    setRoadlineData(filter_result);


  } else if ((lebarSelectionValue === 0 && isNaN(lebarValue) === true) && (panjangSelectionValue === 1 && isNaN(panjangValue) === false)) {
    // Lebih dari (<)
    console.log('4');

    for (let i = 0; i < searchData.length; i++) {
      
      if (searchData[i].PANJANG_JA > panjangValue) {         
        filter_result.push(searchData[i]);
      }

    }

    console.log(filter_result);
    message = "Hasil Carian: "+filter_result.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    setRoadlineData(filter_result);
    
  }

  // Both Lebar & Panjang have values
  if ((lebarSelectionValue === 0 && isNaN(lebarValue) === false) && (panjangSelectionValue === 0 && isNaN(panjangValue) === false)) {
    // Lebar - Kurang dari (<) , Panjang - Kurang dari (<)
    console.log('5');

    for (let i = 0; i < searchData.length; i++) {
      
      if (searchData[i].LEBAR_JALA < lebarJalan && searchData[i].PANJANG_JA < panjangValue) {         
        filter_result.push(searchData[i]);
      }

    }

    console.log(filter_result);
    message = "Hasil Carian: "+filter_result.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    setRoadlineData(filter_result);


  } else if ((lebarSelectionValue === 1 && isNaN(lebarValue) === false) && (panjangSelectionValue === 1 && isNaN(panjangValue) === false)) {
    //Lebar -  Lebih dari (>) , Panjang - Lebih dari (>)
    console.log('6');

    for (let i = 0; i < searchData.length; i++) {
      
      if (searchData[i].LEBAR_JALA > lebarJalan && searchData[i].PANJANG_JA > panjangValue) {         
        filter_result.push(searchData[i]);
      }

    }

    console.log(filter_result);
    message = "Hasil Carian: "+filter_result.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    setRoadlineData(filter_result);


  } else if ((lebarSelectionValue === 0 && isNaN(lebarValue) === false) && (panjangSelectionValue === 1 && isNaN(panjangValue) === false)) {
   // Lebar - Kurang dari (<) , Panjang - Lebih dari (>)
    console.log('7');

    for (let i = 0; i < searchData.length; i++) {
      
      if (searchData[i].LEBAR_JALA < lebarJalan && searchData[i].PANJANG_JA > panjangValue) {         
        filter_result.push(searchData[i]);
      }

    }

    console.log(filter_result);
    message = "Hasil Carian: "+filter_result.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    setRoadlineData(filter_result);


  } else if ((lebarSelectionValue === 1 && isNaN(lebarValue) === false) && (panjangSelectionValue === 0 && isNaN(panjangValue) === false)) {
    //Lebar -  Lebih dari (>) , Panjang - Kurang dari (<)
    console.log('8');

    for (let i = 0; i < searchData.length; i++) {
      
      if (searchData[i].LEBAR_JALA > lebarJalan && searchData[i].PANJANG_JA < panjangValue) {         
        filter_result.push(searchData[i]);
      }

    }

    console.log(filter_result);
    message = "Hasil Carian: "+filter_result.length+" rekod ditemui";
    setResultMessage(message);
    setMessageDisplay(true);
    setRoadlineData(filter_result);


  }

  // END - OTHERS SEARCH CONDITION
}


const tooltipLJ = (
  <Tooltip id="tooltipInfo">
    <label>Masukkan tanda "&lt;" atau "&gt;" untuk tetapan carian lebih rendah/tinggi daripada nilai purata lebar jalan. Contoh: "&gt;"</label>
  </Tooltip>
);

const tooltipLJ_value = (
  <Tooltip id="tooltipInfo">
    <label>Masukkan nilai lebar jalan &#40;m&#41;</label>
  </Tooltip>
);

const tooltipPJ = (
  <Tooltip id="tooltipInfo">
    <label>Masukkan tanda "&lt;" atau "&gt;" untuk tetapan carian lebih rendah/tinggi daripada nilai panjang jalan. Contoh: "&gt;"</label>
  </Tooltip>
);

const tooltipPJ_value = (
  <Tooltip id="tooltipInfo">
    <label>Masukkan nilai panjang jalan &#40;km&#41;</label>
  </Tooltip>
);

  return (

    <div className='AdvanceSearchDiv'>
    
    {/* <Container> */}
      {/* Navbar - header */}
      <Row xs={12} sm={12} lg={12}>
      <Navbar expand="lg" className="navbarStyle">
      <Container>
      <label>Carian Terperinci</label>
      </Container>
      </Navbar>
      </Row>
      
      {/* Body Content */}
      
    
    

    <Row>
  
    {/* Search Box Col */}
    <Col xs={12} sm={3} lg={3} className='searchBoxCol'>
    <br/> 
    <Row className="">
      {/* <Row>
      <Col sm={6} lg={6}><label>Melalui:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' value="Daerah (JKR & JPS)" disabled><option >Daerah (JKR & JPS)</option></select></Col>
      </Row> */}
      {/* <p></p> */}

      {/* handleDaerah(event: React.ChangeEvent<HTMLInputElement>): string */}
      <Row>
      <Col sm={6} lg={6}><label>Daerah:</label></Col>
      <Col sm={6} lg={6}>
      <select className='dropDownSearch' id="daerahSelection" value={selectedDaerah} onChange={handleDaerah}>
        <option value="0">Semua</option>
        <option>Bentong</option>
        <option>Bera</option>
        <option>Jerantut</option>
        <option>Kuantan</option>
        <option>Lipis</option>
        <option>Maran</option>
        <option>Pekan</option>
        <option>Raub</option>
        <option>Rompin</option>
        <option>Temerloh</option>
        </select></Col>
      </Row>
      {/* <p></p> */}

      <Row>
      <Col sm={6} lg={6}><label>Kategori:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id="kategoriSelection" value={selectedKategori} onChange={event => handleKategori(event, '')}>
        <option value="0">Semua</option>
        {kategori.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
      </select></Col>
      </Row>

      <Row>
      <Col sm={6} lg={6}><label>Tahun:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id='tahunSelection' value={selectedTahun} onChange={handleTahun}>
        <option value="0">Semua</option>
        {tahun.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
        </select></Col>
      </Row>

      <Row>
      <Col sm={6} lg={6}><label>Mukim:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id='mukimSelection' value={selectedMukim} onChange={event => handleMukim(event, '')}>
        <option value="0">Semua</option>
        {mukim.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
        </select></Col>
      </Row>
      {/* <p></p> */}

      <Row>
      <Col sm={6} lg={6}><label>Nama Kampung:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id='kampungSelection' value={selectedKampung} onChange={event => handleKampung(event, '')}>
        <option value="0">Semua</option>
        {kampung.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
        </select></Col>
      </Row>
      {/* <p></p> */}

      <Row>
      <Col sm={6} lg={6}><label>Nama Ladang:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id='ladangSelection' value={selectedLadang} onChange={event => handleLadang(event, '')}>
        <option value="0">Semua</option>
        {ladang.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
        </select></Col>
      </Row>

      <Row>
      <Col sm={6} lg={6}><label>Nama Projek:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id='projekSelection' value={selectedProjek} onChange={event => handleProjek(event, '')}>
        <option value="0">Semua</option>
        {projek.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
        </select></Col>
      </Row>

      <Row>
      <Col sm={6} lg={6}><label>Nama Jalan:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id='namaJalanSelection' value={selectedJalan} onChange={handleJalan}>
        <option value="0">Semua</option>
        {namaJalan.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
      </select></Col>
      </Row>

      <Row>
      <Col sm={6} lg={6}><label>Kategori Jalan:</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id='kategoriJalanSelection' value={selectedKategoriJalan} onChange={event => handleKategoriJalan(event, '')}>
        <option value="0">Semua</option>
        {kategoriJalan.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
        </select></Col>
      </Row>
      {/* <p></p> */}

      <Row>
      <Col sm={6} lg={6}><label>Jenis Permukaan Jalan (SRT):</label></Col>
      <Col sm={6} lg={6}><select className='dropDownSearch' id='jenisPermukaanSelection' value={selectedPermukaanJalan} onChange={handleJenisPermukaan}>
        <option value="0">Semua</option>
        {permukaanJalan.map((row, index) => (
          <option key={index}>{row}</option>
        ))}
      </select></Col>
      </Row>
      {/* <p></p> */}

      <Row>
      <Col sm={6} lg={6}><label>Purata Lebar Jalan (m):</label></Col>
      <Col sm={6} lg={6}>

      <Row>
      <Col sm={5} lg={5}>
      {/* <select id='lebarJalanCondition'>
        <option value='0'>{lessThanLebar}</option>
        <option value='1'>{greaterThanLebar}</option>
      </select> */}
      <OverlayTrigger placement="top" overlay={tooltipLJ}>
      <Form.Control id='lebarJalanCondition' type="text" size="sm" minLength={1} maxLength={1}/>
      </OverlayTrigger>
      </Col>

      <Col sm={7} lg={7}>
      <OverlayTrigger placement="top" overlay={tooltipLJ_value}>
      <Form.Control id='lebarJalan' type="number" size="sm" onChange={handleLebarJalan} value={lebarJalan} step={0.01}/>
      </OverlayTrigger>  
      </Col>
      </Row>

      </Col>
      </Row>
      {/* <p></p> */}

      <Row>
      <Col sm={6} lg={6}><label>Panjang Jalan (km):</label></Col>
      <Col sm={6} lg={6}>

      {/* <select id='panjangJalanCondition'>
        <option value='0'>{lessThanPanjang}</option>
        <option value='1'>{greaterThanPanjang}</option>
      </select> */}

      <Row>
        <Col sm={5} lg={5}>
        <OverlayTrigger placement="top" overlay={tooltipPJ}>
        <Form.Control id='panjangJalanCondition' type="text" size="sm" minLength={1} maxLength={1}/>
        </OverlayTrigger>
        </Col>
        <Col sm={7} lg={7}>
         <OverlayTrigger placement="top" overlay={tooltipPJ_value}>
          <Form.Control id='panjangJalan' type="number" size="sm" onChange={handlePanjangJalan} value={panjangJalan} step={0.01}/>
         </OverlayTrigger>
        </Col>
      </Row>
    
      </Col>
      </Row>
      {/* <p></p> */}
    </Row>

    <br/>

    {/* Button row */}
    <Row>
    <Col sm={6} lg={6}>
      <Button variant="success" onClick={goBack}>Kembali ke Peta</Button>{' '}
    </Col>
    <Col sm={6} lg={6} className='buttonCari'>
      <Button variant="secondary" onClick={resetButton}>Reset</Button>&nbsp;
      <Button variant="primary" onClick={cariButton}>Cari</Button>
    </Col>
    </Row>


    </Col>


    {/* Datatable Col */}
    <Col xs={12} sm={9} lg={9} >

    <Row className='rowHeader'>
    <Col xs={7} sm={8} lg={10}>
    {loadingSpinner && <div className='spinner1'><h6><Spinner animation="border" /> Loading...</h6></div>}
    {isMessageDisplay && <label className='messageDisplayStyle'><Badge bg='info'>{resultMessage}</Badge></label>}
    </Col>
    <Col xs={5} sm={4} lg={2} className='buttonCetak'><Button variant="primary"  onClick={downloadPDF}>Cetak</Button></Col> 
    </Row>

    <Row className='dataTableCol'>     
    <Table id='dataTableAdvanceSearch' striped bordered hover>
        <thead>
          <tr key="dataTableRoadlineData">
           <th>#</th>
           <th>Daerah</th>
           <th>Kategori</th>
           <th>Dun</th>
           <th>Mukim</th>
           <th>Kod Jalan</th>
           <th>ID Jalan</th>
           <th>Nama Kampung</th>
           <th>Nama Ladang</th>
           <th>Nama Projek</th>
           <th>Nama Jalan</th> 
           <th>Panjang Jalan(km)</th>
           <th>Purata Lebar Jalan(m)</th>
           <th>SRT (KOD)</th>
           <th>SRT</th>
           <th>Tahun</th>
           <th>Tarikh Kemaskini</th>
           <th>Pihak Kuasa</th>
           <th>Kategori Jalan</th>
           <th>Pengurusan</th>
           <th>Pengukuran</th>
            <th></th>
            
          </tr>
        </thead>
        <tbody>
          {roadlineData.map((row, index) => (
            <tr key={row.id}>
              <td>{index + 1}</td>
              <td>{row.DAERAH}</td>
              <td>{row.KATEGORI}</td>
              <td>{row.DUN}</td>
              <td>{row.MUKIM}</td>
              <td>{row.KOD_JALAN}</td>
              <td>{row.ID_JALAN}</td>
              <td>{row.NAMA_KAMPU}</td>
              <td>{row.NAMA_LADAN}</td>
              <td>{row.PROJEK}</td>
              <td>{row.NAMA_JALAN}</td>
              <td>{row.PANJANG_JA}</td>
              <td>{row.LEBAR_JALA}</td>
              <td>{row.SRT_KOD}</td>
              <td>{row.SRT}</td>
              <td>{row.TAHUN}</td>
              <td>{row.TARIKH_KEM}</td>
              <td>{row.PIHAK_KUAS}</td>
              <td>{row.KATEGORI_J}</td>
              <td>{row.AUTHORITY}</td>
              <td>{row.SURVYD_BY}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Row>
      <Row>
        <span></span>
      </Row>


      </Col>
    
      </Row>
      
      {/* Button in footer */}
      <Row></Row>

    
    
    
    {/* </Container> */}

    {/* <AdvanceSearchTest></AdvanceSearchTest> */}
    </div>
  )

};
  
export default AdvanceSearch
